
export default {
  middleware: 'auth',
  auth: 'guest',

  asyncData({ app, i18n, query, $config: { baseURL } }) {
    const images = [
      {
        urlImage: '/images/index/tom-chen--h-OMkA5JTM-unsplash.jpg',
        photographer: 'tomchen',
      },
      {
        urlImage: '/images/index/david-rodrigo-Fr6zexbmjmc-unsplash.jpg',
        photographer: 'david__r',
      },
      {
        urlImage: '/images/index/david-rodrigo-kZ1zThg6G40-unsplash.jpg',
        photographer: 'david__r',
      },
      {
        urlImage: '/images/index/andreas-m-BLGcfeNnx4U-unsplash.jpg',
        photographer: 'nextvoyage_pl',
      },
      {
        urlImage: '/images/index/alexander-kaunas-xEaAoizNFV8-unsplash.jpg',
        photographer: 'akaunas',
      },
      {
        urlImage: '/images/index/valeriy-ryasnyanskiy-bduYp1pYxjM-unsplash.jpg',
        photographer: 'ryasnyansky',
      },
      {
        urlImage: '/images/index/paolo-nicolello-2gOxKj594nM-unsplash.jpg',
        photographer: 'paul_nic',
      },
      {
        urlImage: '/images/index/sara-dubler-Koei_7yYtIo-unsplash.jpg',
        photographer: 'ahungryblonde_',
      },
      {
        urlImage: '/images/index/the-anam-J9O3WcJ5N74-unsplash.jpg',
        photographer: 'theanamcxr',
      },
      {
        urlImage: '/images/index/lucas-davies-iP8ElEhqHeY-unsplash.jpg',
        photographer: 'lucas_davies',
      },
      {
        urlImage: '/images/index/jamie-davies-7iNteV_zTRU-unsplash.jpg',
        photographer: 'jamie_davies',
      },
      {
        urlImage: '/images/index/veliko-karachiviev-hSvagWirWPA-unsplash.jpg',
        photographer: 'ikoveliko',
      },
      {
        urlImage: '/images/index/leonard-cotte-R5scocnOOdM-unsplash.jpg',
        photographer: 'ettocl',
      },
      {
        urlImage: '/images/index/florian-wehde-milUxSbp4_A-unsplash.jpg',
        photographer: 'florianwehde',
      },
      {
        urlImage: '/images/index/fabien-maurin-x-S6ZlJ6dP0-unsplash.jpg',
        photographer: 'abienmaurin',
      },
      {
        urlImage: '/images/index/sumit-chinchane-jWKk-0ZBUyg-unsplash.jpg',
        photographer: 'sumit_ac',
      },
      {
        urlImage: '/images/index/mathew-schwartz-gsllxmVO4HQ-unsplash.jpg',
        photographer: 'cadop',
      },
      {
        urlImage: '/images/index/mg-cthu--1h_NN3nqzI-unsplash.jpg',
        photographer: 'mgcthu',
      },
      {
        urlImage: '/images/index/david-kohler-VFRTXGw1VjU-unsplash.jpg',
        photographer: 'davidkhlr',
      },
    ]

    return {
      selectedImage: images[Math.floor(Math.random() * images.length)],
    }
  },

  data() {
    return {
      errors: {},
      loginForm: {
        email: '',
        password: '',
        remember: true,
      },
    }
  },

  head() {
    return {
      title: this.$i18n.t('Login'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('Login'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$i18n.t('Login'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$i18n.t('Login'),
        },
      ],
    }
  },

  methods: {
    async login(event) {
      this.errors = {}

      try {
        await this.$auth.logout()
      } catch (error) {}

      await this.$auth
        .loginWith('cookie', { data: this.loginForm })
        .then(() => {})
        .catch((error) => {
          if (error.response && error.response.data) {
            this.errors = error.response.data.errors

            // this.$toast.error(this.$i18n.t(error.response.data.message))
          }
        })
    },
  },
}
